.commonSlider .HolidayCardSlider .slideItem .itemCard.packageCard,
.HolidayCard .itemCard.packageCard {
    border-radius: 6px;
    box-shadow: none;
    background-color: #ffffff;
    padding: 10px;
    cursor: pointer;
    height: 450px;
    width: 329px;
    border: 1px solid #e7e7e7;
    position: relative;
    z-index: 1;
}

.packageCard .daysbadge {
    padding: 5px 10px;
    background: var(--card-d-n-info-bg-color, #000);
    border-radius: 13px;
    color: var(--card-d-n-info-color, #fff);
    position: absolute;
    margin-top: -15px;
    right: 5px;
    z-index: 1;
}

.packageCard .sliderCardShadow {
    box-shadow: none;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.packageCard .destinationWrapper .redText {
    font-size: 15px;
    font-weight: 700;
}

.HolidayCard .itemCard__ftr,
.holidaysliderBreakup .itemCard__ftr {
    position: static;
    margin-top: 14px;
}

.packageCard .sliderCard {
    width: 308px;
    border-radius: 6px;
    height: 480px;
}

.packageCard .flightIcon {
    background-position: -23px -4px;
    width: 17px;
    height: 19px;
}

.packageCard .holidayItineraryWrapper {
    background: transparent;
    height: 50px;
    padding-right: 10px px;
}

.form-alin {
    display: inline-block;
}

.message {
    height: 20px;
}



.mar {
    position: relative;
    top: -23px;
}

.text {
    margin-bottom: 10px;
}

.sub-btn {
    width: 70%;
    margin-left: 40px;
    border-radius: 0px;
    margin-bottom: 20px;
}

.banner1 {
    background-image: url("./../images/blog/banner1.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.banner2 {
    background-image: url("./../images/blog/vally.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.banner3 {
    background-image: url("./../images/blog/island.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.banner4 {
    background-image: url("./../images/blog/goa.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.banner5 {
    background-image: url("./../images/blog/buddhist-pilgrim-banner.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.banner6 {
    background-image: url("./../images/blog/International-banner_1236.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.banner7 {
    background-image: url("./../images/blog/night.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.banner8 {
    background-image: url("./../images/blog/medicaltourism.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.hotelbanner {
    background-image: url("./../images/sunset-pool.jpg");
    background-attachment: fixed;
    background-size: cover;
}




.info {
    background-image: url("./../images/blogging.jpg");
    background-attachment: fixed;
    background-size: cover;
}

.text1 {
    margin-top: 122px;
    color: white;
    /* background-color: black; */
    /* border-radius: 10px; */
    /* opacity: 0.7; */

    width: max-content;
    padding: 0px 2px 4px 2px;
}

.nav-tabs .nav-link {
color: gray;
}


.nav-tabs .nav-link.active{

        border-bottom:3px solid #001e79 !important;
    color:#001e79 !important;
        font-weight: 500;
    }

.overlaywhite-text{

    font-size: 20px;
    font-weight: 500;
    background-color: white;
    /* border-radius: 10px; */
    padding: 0px 5px 0px 5px;
    opacity: 0.7;
}

.card {
    border-radius: 0px;
    margin-top: 10px;
    margin-bottom: 25px;
}

.b2-content {
    color: white;
    font-size: 15px;
}

.border-bt {
    border-radius: 0px;
}

@media only screen and (max-width:600px){
    .btn_hotel{
        color:white;
        width:100%;
    }
    .btn_hotel-mod{
width: 100%;
        transform: translateY(0%);

    }

    .cardtest{
border-radius: 10px;
        border: 1px solid #c7c7c7; 
    }

    .t-img{

    width: 100%;
    height: fit-content;
    border-radius: 10px;

  }

  .scroll{

    height: 80px;
    overflow-x: scroll;
    margin: 0px 0px 17px 0px;
    cursor: grabbing;
  }

  .fixed_p{
    background-color: white;
    /* box-shadow: -1px 8px 10px 0px grey; */
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #b9b9b9;
    margin-top:12px;
}

  .radio_text{
    font-size: 14px;
    margin-left: 5px;
    color: black;
    font-weight: 500;
}

    .drop_down{
        display:none;
        background-color: #fcfdff;
    height:150px;
    overflow-y: hidden;
    border: 1px solid #afe0ff;
 
    box-shadow: 0px 6px 8px 0px grey;
    }
    
  
    .drop_text{

        padding: 12px;
    }
    
    
    
    .show{
    display:block;
    height: auto;
    }
    .sub_drop_1{
        list-style-type: none;
        padding-top: 20px;

    }
    .card_travel{
border-radius:15px;
width:100%;
height:max-content;
box-shadow: 0px 0px 10px 3px grey;
background-color: white;


   }
   
    .carousel_small_mob{
        height: 115px !important;

    }

    .travel_img{

        height: 250px;
    }
    
    .btns{

    color: white;
    width: 100%;
    }

    .form-controls {
        width: 75% !important;
    margin-left: 12px;
    margin-bottom: 10px;
    border-radius: 0px;
    height: 40px;
    display: block;
    border: none;
    padding: 5px;
}

.travel_btns{

    width: 38%;
    line-height: 2;
    font-size: 7px;
    font-weight: 500;
}

.space_up{
    padding-top: 84px;
}

}


@media only screen and (min-width:768px){
    .btn_hotel{
        color:white;
        width:100%;
        
    }
 

    .btn_hotel-mod{
width: 40% !important;
        transform: translateY(0%);

    }
    .cardtest{

        border: 1px solid #c7c7c7;
    height: 370px;
    border-radius: 10px; 
    }
 
    .t-img{
    width: 100%;
    height: fit-content;
    border-radius: 10px;


  }
  .radio_text{
    font-size: 12px;
    margin-left: 5px;
    font-weight: 500;
}
.fixed_p{
    background-color: white;
    box-shadow: -1px 8px 10px 0px grey;
    border-radius: 10px;
  
    padding: 20px;
}
   
    .drop_down{
        display:none;
        background-color: #fcfdff;
    height:150px;
    overflow-y: hidden;
    border: 1px solid #afe0ff;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 6px 8px 0px grey;
    }
    
    

    .drop_text{

        padding: 12px;
    }
    
    
    
    .show{
    display:block;
    height:auto;
    
    }
    .sub_drop_1{
        list-style-type: none;
        padding-top: 20px;

    }

    .space_up{

    padding-top:80px;
}
   
.travel_btns{}


.travel_img{

    height: 400px;
}

.card_travel{
    border-radius:15px;
    width:100%;
    height:max-content;
    box-shadow: 0px 0px 10px 3px grey;
    background-color: white;
    
       }
    .btns{

    color: white;
    width: 100%;
    }

    .form-controls {
        width: 100% !important;
        margin-left: 12px;
        margin-bottom: 10px;
        border-radius: 0px;
        height: 37px;
        border: none;
        display: block;
        padding-left: 10px;
}

}


@media only screen and (min-width:1200px){
    .btn_hotel{
        color:white;
        width:100%;

    }

    .btn_hotel-mod{
width: 100% !important;
        transform: translateY(85%);
height: 45px;
    }
   
    .cardtest{

        border: 1px solid #c7c7c7;
    height: 400px;
    border-radius: 10px; 
    }
   
    .down1{
    position: absolute;
    z-index: 2;
    right: 24%;
    margin: -134px 0px 0px 0px;
display:none;
   }

   .drop_list{

    display: block;
   }

   .sub_drop_1:hover .down1{

    display:block;
   }
   
    .t-img{
        width: 100%;
        height: 300px;
        border-radius: 10px;
    

    }
    .radio_text{
        font-size: 14px;
        margin-left: 5px;
        font-weight: 500;
    }
    
    .fixed_p{
        background-color: white;
        box-shadow: -1px 8px 10px 0px grey;
        border-radius: 10px;
     
        padding: 30px;
    }

    .drop_down{
    display:none;
    background-color: #fcfdff;
height:150px;
overflow-y: hidden;
border: 1px solid #afe0ff;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 6px 8px 0px grey;
}



.drop_text{

    padding: 12px;
}

.show{
display:block;
height: auto;

}

.sub_drop_1{
    position: relative;
padding-top:22px;
    list-style-type: none;
}
    

.card_travel{
    border-radius:15px;
    width:100%;
    height:max-content;
    box-shadow: 0px 0px 10px 3px grey;
    background-color: white;
       }
.travel_btns{


}

.travel_img{

    height: 500px;
}

    
    .btns{
color: white;
    width: 100%;
    
    }

    .form-controls {
        padding-left: 10px;
        border: none;
        width: 50% !important;
        margin-left: 12px;
        margin-bottom: 10px;
        border-radius: 0px;
        line-height: 2;
}

}

.form_edit_travel{
    width: 100%;
    line-height: 2;
    border: 2px solid #bfbfbf;
    border-radius: 5px;
    color: gray;
    padding-left: 5px;

}



.form_text1{
    margin-bottom: 0px;
    font-size: 20px;
    color: black;
}

.table_text{

    text-align: center;
    line-height: 3;
    background-color: orange;
    width: 100%;
    color: white;
    font-weight: 500;
}

.center_body{

    position :relative;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.price_list{
color: orange;
font-size: 15px;
font-weight:500;
padding: 10px 0px 10px 0px;

}

.card_des{
font-size:18px;
    padding:0px 15px 0px 15px;
    margin-bottom: 0px;
}

.img_user{
    border-radius: 50%;
    height: 100px;
    width: max-content;
    border: 2px solid #ff2854;
}
.card_text2{

    text-align: center;
    color: black;
    font-weight: 500;
    font-size: 25px;
}

.card_text3{

    color: #424242;
    padding:10px;
}

.border{
padding:15px;
border-radius: 10px;

}

.form_text-top{

    text-align: center;
    font-size: 30px;
    font-weight: 500;
    color: black;
}

.hotel_form{
    width: 100%;
    color: gray;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #b5b5b5;
    margin-top: 0px;
    margin-bottom: 10px;
}





.tb_btn{
width:100%;
color: white;

}

.main_content{

    background-color: white;
    box-shadow: 0px 1px 8px 0px grey;
    margin-top: 12px;
    border-radius: 5px;
    padding: 12px;
}

.intro{
    border-top: 3px solid orange;
    border-bottom: 3px solid orange;

}

.intro_text{

    color: black;
    font-size: 20px;
    font-weight: 500;
}

.intro_2{}

.intro_text2{
    text-align: center;
    font-size: 25px;
    color: black;
    font-weight: 500;

}

.cards_img{}

.cards2{
    box-shadow: 7px 5px 13px 0px grey;
    width: 100%;
    
    border-radius: 10px;

}

.hos_text1{
    font-weight: 500;
    font-size: 25px;
    padding: 2% 0px 9% 3%;
    border-bottom: 1px solid gray;

}


.hos_text2{

    color: black;
    font-size: 18px;
    padding: 3px 5px 0px 13px;
    font-weight: 450;
}

.mng_text_head{
    color: black;
    font-size: 25px;
    text-align: center;
    font-weight: 500;

}

.mag-text{
    color: black;
    font-size: 20px;
    padding: 2% 0px 15px 3%;

}

.manage_img{
    border-radius: 5px;
    box-shadow: 0px 0px 10px 0px grey;

}

.pat_text{

    color: black;
    text-align: left;
    font-size: 25px;
    font-weight: 500;
}

.pat_img{

    height: 150px;
    width: 150px;
}



@media only screen and (max-width:600px){
    .carimg2{

height:100px;
    }
    
    .icon-bar {
        position: fixed;
        top: 60%;
        right:-70px;
        z-index: 1000;
        transform: rotate(-90deg);        
      }

   .carousel_text_top{
    position: absolute;
    bottom: 40%;

   }
   
    .carousel_cover{

    position: relative;
    margin-top:22%;    
    }

    .carsul_btn{
        position: relative;
        margin-left: 12px;
        z-index: 2;
        bottom: 70px;
        border: none;
        line-height: 2.3;
        background-color: #ebc508;
        color: white;
        border-radius: 5px;

 }

 .carimg{

    height:350px;
 }

 .carsul_search{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -1%);
    z-index: 1;
    width: 90%;
    line-height: 2.5;
    border: none;
    border-radius: 5px;
    padding-left: 1%;
    color: black;
    padding-left: 3%;

 }

}
@media only screen and (min-width:768px){
    .carimg2{
height: 300px;
        
    }
   
    .icon-bar {
        position: fixed;
        top: 50%;
        right:-70px;
        z-index: 1000;
        transform: rotate(-90deg);        
      }

    .carimg{

        height:500px;
     }
   
    .carousel_text_top{

            position: absolute;
            bottom: 55%;
    }
    .carimg{

        height:400px;
     }
    

    .carousel_cover{

        position: relative;
        margin-top: 10%;
        }
 
    .carsul_btn{
    position: relative;
    margin: 1%;
    bottom:85px;
    z-index: 2;
    right: 0px;
    transform: translate(0px, -50%);
    border: none;
    line-height: 2.5;
    background-color: #ebc508;
    color: white;
    border-radius: 5px;

  } 
  
  .carsul_search{
    display: block;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -5%);
    z-index: 1;
    width: 45%;
    line-height: 2.5;
    border: none;
    border-radius: 5px;
    padding-left: 1%;
    color: black;
  }

}


@media only screen and (min-width:1200px){
    .carimg2{
        height: 300px;

        
    }
    
    .icon-bar {
        position: fixed;
        top: 50%;
        right:-70px;
        z-index: 1000;
        transform: rotate(-90deg);        
      }

    .carimg{

        height:335px;
     }
    .carousel_text_top{
        position: absolute;
        bottom: 55%;
  
       }
       
       .carimg{

        height:450px;
     }
    
    
    .carousel_cover{

        position: relative;
        margin-top:6%;
        }

    .carsul_btn{
    position: relative;
    margin: 1%;
    bottom: 100px;
    z-index: 2;
    right: 0px;
    transform: translate(0px, -50%);
    border: none;
    line-height: 2.5;
    background-color: #0d6efd;
    color: white;
    border-radius: 5px;

}

.carsul_search{

    display: block;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -1%);
    z-index: 1;
    width: 35%;
    line-height: 2.5;
    border: none;
    border-radius: 5px;
    padding-left: 1%;
    color: black;
}

}

@media only screen and (min-width:1400px){
    .carimg2{
height: 300px;
        
    }
    
    .icon-bar {
        position: fixed;
        top: 50%;
        right:-70px;
        z-index: 1000;
        transform: rotate(-90deg);        
      }

    .carimg{

        height:550px;
     }


    .carousel_text_top{
        position: absolute;
        bottom: 55%;
    
       }
       
    
    .carousel_cover{

        position: relative;
        }
  
    .carsul_btn{
        position: relative;
        margin: 1%;
        bottom:80px;
        z-index: 2;
        right: 0px;
        transform: translate(0px, -50%);
        border: none;
        line-height: 2.5;
        background-color: #ebc508;
        color: white;
        border-radius: 5px;
    
    }
    
    .carsul_search{
    
        display: block;
        position: absolute;
        top: 65%;
        left: 50%;
        transform: translate(-50%, -1%);
        z-index: 1;
        width: 35%;
        line-height: 2.5;
        border: none;
        border-radius: 5px;
        padding-left: 1%;
        color: black;
    }
    
    }

 
      
      .icon-bar button {
        display: block;
        text-align: center;
        padding: 5px;
        transition: all 0.3s ease;
        color: white;
        font-size: 20px;
        text-decoration: none;
      }
      
      .icon-bar button:hover {
        background-color: #000;
        text-decoration: none;
      }
      
      a
      {
          text-decoration: none;
      }
      .facebook {
        background: #3B5998;
        color: white;
      }
      
      .twitter {
        background: #55ACEE;
        color: white;
      }
      
     
      
      .content {
        margin-left: 75px;
        font-size: 30px;
      }

    /*Slick CSS Start */

    .slick_text{
font-size: 18px;
line-height: 1.5;
margin-bottom: 0;
    }

    @media only screen and (max-width:600px){

.card_slik{
        margin-bottom:2%;
        background-color: white;
        padding: 0% 0% 3% 0%;
        width: 90%;
        margin-left:18px;
        /* display: flex; */
  justify-content: center;
  align-items: center;
        border: 1px solid gray;
        cursor: grab;

    }

    .card_slik1{
        margin-bottom:2%;
        background-color: white;
        padding: 0% 0% 3% 0%;
        width: 90%;
        margin-left:18px;
        /* display: flex; */
  justify-content: center;
  align-items: center;
        border: 1px solid gray;
        cursor: grab;

    }

    .card-img-top_slik{

        width: 100%;
        border-radius: 20px;
        height: 220px;


    }
    }

    @media only screen and (min-width:768px){
        .card_slik{
            margin: 0% 2% 10% 2%;
            background-color: white;
            padding: 0% 0% 3% 0%;
            width: 98%;
            height: 325px;
border: 1px solid gray;
cursor: grab;

        }

        .card_slik1{
            margin: 0% 2% 10% 2%;
            background-color: white;
            padding: 0% 0% 3% 0%;
            width: 98%;
            height: 220px;
border: 1px solid gray;
cursor: grab;

        }

    .card-img-top_slik{


        width: 100%;
        border-radius: 20px;
        height: 150px;

    }


    }

    @media only screen and (min-width:1200px){
        .card_slik{
            margin: 0% 2% 10% 2%;
            background-color: white;
            padding: 0% 0% 3% 0%;
            width: fit-content;
            height: 335px;
border: 1px solid gray;
cursor: grab;
        }

        .card_slik1{
            margin: 0% 2% 10% 2%;
            background-color: white;
            padding: 0% 0% 3% 0%;
            width: fit-content;
            height: 250px;
border: 1px solid gray;
cursor: grab;
        }

        .card-img-top_slik{

                width: 265px;
                border-radius: 20px;
                height: 200px;


        }


    }

    /*Slick CSS End */

    /* DropDown Start */

    @media only screen and (max-width:600px){


        .dropdowns{
            position: relative;
            top: 25%;
            display: flex;
            justify-content: center;
            background-color: white;
            padding: 4%;
            border: 1px solid #b5b5b5;
            border-radius: 5px;
       
    
        }
    
        .sub_drops{
            position: absolute;
            display: none;
            width: 100%;
            background-color: #f3fbff;
            top: 102%;
            border: 1px solid #dddddd;
            border-radius: 5px 5px 0px 0px;
        }
    
        .dropdowns:hover .sub_drops{
    display: block;
    
        }
    
        .dropdown_title{
    margin-bottom:0px;
    
        }
    
        .drop_lists{
    
            display: block;
            padding: 5% 0% 5% 0%;
            text-align: center;
            color: black;
        }
    
        .drop_a{
    display:none;
       
        }
    
        .drop_a_list{
            display: block;
            padding: 5% 0% 5% 0%;
            text-align: center;
            color: black;
        }
    
        .drop1:hover .drop_a{
            position: absolute;
            display: block;
            left: 0%;
            bottom: -62%;
            background-color: #f3fbff;
            width: 100%;
            border-left: 1px solid #dddddd;
            border-bottom: 1px solid #dddddd;
            border-right: 1px solid #dddddd;
            border-radius: 0px 0px 5px 5px;
            z-index: 2;
        }
    }

    @media only screen and (min-width:768px){


        .dropdowns{
            position: relative;
            top: 25%;
            display: flex;
            justify-content: center;
            background-color: white;
            padding: 4%;
            border: 1px solid #b5b5b5;
            border-radius: 5px;
       
    
        }
    
        .sub_drops{
            position: absolute;
            display: none;
            width: 100%;
            background-color: #f3fbff;
            top: 102%;
            border: 1px solid #dddddd;
            border-radius: 5px;
        }
    
        .dropdowns:hover .sub_drops{
    display: block;
    
        }
    
        .dropdown_title{
    margin-bottom:0px;
    
        }
    
        .drop_lists{
    
            display: block;
            padding: 5% 0% 5% 0%;
            text-align: center;
            color: black;
        }
    
        .drop_a{
    display:none;
       
        }
    
        .drop_a_list{
            display: block;
            padding: 5% 0% 5% 0%;
            text-align: center;
            color: black;
        }
    
        .drop1:hover .drop_a{
            position: absolute;
            display: block;
            left: -100%;
            bottom: 20%;
            background-color: #f3fbff;
            width: 100%;
            border-top: 1px solid #dddddd;
            border-bottom: 1px solid #dddddd;
            border-left: 1px solid #dddddd;
            border-radius: 5px 0px 0px 5px;
        }
    }

    @media only screen and (min-width:1200px){


        .dropdowns{
            position: relative;
            top: 25%;
            display: flex;
            justify-content: center;
            background-color: white;
            padding: 4%;
            border: 1px solid #b5b5b5;
            border-radius: 5px;
       
    
        }
    
        .sub_drops{
            position: absolute;
            display: none;
            width: 100%;
            background-color: #f3fbff;
            top: 102%;
            border: 1px solid #dddddd;
            border-radius: 5px;
        }
    
        .dropdowns:hover .sub_drops{
    display: block;
    
        }
    
        .dropdown_title{
    margin-bottom:0px;
    
        }
    
        .drop_lists{
    
            display: block;
            padding: 5% 0% 5% 0%;
            text-align: center;
            color: black;
        }
    
        .drop_a{
    display:none;
       
        }
    
        .drop_a_list{
            display: block;
            padding: 5% 0% 5% 0%;
            text-align: center;
            color: black;
        }
    
        .drop1:hover .drop_a{
            position: absolute;
            display: block;
            left: 100%;
            bottom: 20%;
            background-color: #f3fbff;
            width: 100%;
            border-top: 1px solid #dddddd;
            border-bottom: 1px solid #dddddd;
            border-right: 1px solid #dddddd;
            border-left: 0px;
            border-radius: 0px 5px 5px 0px;
        }
    }


    /* DropDown End */


.carousel-indicators
{
    display:"none"
}




@media only screen and (max-width:600px){
.slide-img{
    width: 100%;
    height: 250px;
    border-radius: 10px;
}
.pricetext
{
    color: #90EE90;
    font-weight: 700;
    font-size: 18px;
    text-decoration: line-through;
    text-decoration-color: red;
    width: 50px;
  }
.glow {
    color: #fff;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #0fa,
        0 0 82px #0fa,
        0 0 92px #0fa,
        0 0 102px #0fa,
        0 0 151px rgb(251, 255, 0);
        font-size: 12px;
  }
.topText
{
    position:absolute;
    top: 0px;
    border-top:150px solid #001e79;
    width:0;
    height: 0;
    font-size: 15px;
    border-right:150px solid transparent;
}


.topText h5 {
    position:absolute;
    text-align: center;
    top: -130px;
    left: -40px;
    position: relative;
    width: 170px;
    height: 80px;
    margin: 0px;
    font-size:14px;
    transform:rorate(-45deg);
    -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);

  /* IE */
  -ms-transform: rotate(-45deg);

  /* Opera */
  -o-transform: rotate(-45deg);

    color:white;
  }


}

@media only screen and (min-width:768px){
.slide-img{
    width: 100%;
    height: 200px;
    border-radius: 10px;
}
.pricetext
{
    color: #90EE90;
    font-weight: 700;
    font-size: 18px;
    text-decoration: line-through;
    text-decoration-color: red;
    width: 50px;
  }
.glow {
    color: #fff;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #0fa,
        0 0 82px #0fa,
        0 0 92px #0fa,
        0 0 102px #0fa,
        0 0 151px rgb(251, 255, 0);
        font-size: 12px;
  }
.topText
{
    position:absolute;
    top: 0px;
    border-top:180px solid #001E79;
    width:0;
    height: 0;
    font-size: 18px;
    border-right:180px solid transparent;
}
.topText h5 {
    position:absolute;
    text-align: center;
    top: -160px;
    left: -30px;
    position: relative;
    width: 170px;
    height: 93px;
    margin: 0px;
    font-size:16px;
    transform:rorate(-45deg);
    -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);

  /* IE */
  -ms-transform: rotate(-45deg);

  /* Opera */
  -o-transform: rotate(-45deg);

    color:white;
  }


}


@media only screen and (min-width:1200px){
.slide-img{
    width: 95%;
    height: 250px;
    border-radius: 10px;
}

.pricetext
{
    color: #90EE90;
    font-weight: 700;
    font-size: 20px;
    text-decoration: line-through;
    text-decoration-color: red;
    width: 50px;
  }
.glow {
    color: #fff;
    text-shadow:
        0 0 7px #fff,
        0 0 10px #fff,
        0 0 21px #fff,
        0 0 42px #0fa,
        0 0 82px #0fa,
        0 0 92px #0fa,
        0 0 102px #0fa,
        0 0 151px rgb(251, 255, 0);
        font-size: 17px;
  }
.topText
{
    position:absolute;
    top: 0px;
    border-top:190px solid #001E79;
    width:0;
    height: 0;
    font-size: 18px;
    border-right:190px solid transparent;
}
.topText h5 {
    position:absolute;
    text-align: center;
    top: -160px;
    left: -30px;
    position: relative;
    width: 170px;
    height: 93px;
    margin: 0px;
    font-size:16px;
    transform:rorate(-45deg);
    -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);

  /* IE */
  -ms-transform: rotate(-45deg);

  /* Opera */
  -o-transform: rotate(-45deg);

    color:white;
  }


}

.box_white{
    position: absolute;
    background-image: linear-gradient(to right, white,transparent);
    height: 100%;
    width: 100%;
    top: 0%;
    overflow-y: hidden;
    box-shadow: inset 20px 0px 20px 0px white;
    border-radius: 10px;

}


.trans_img{
    width: 100%;
    height: 300px;
    border-radius: 10px;
}

.cover_box{
position: relative;
border-radius: 10px;
padding-left: 0px;
padding-right: 0px;
}

.border-trans{
    box-shadow: 0px 0px 20px 0px #cbcbcb;;
    border-radius: 10px;    

}

.white_card{
    box-shadow: 0px 2px 20px 0px grey;
    border-radius: 10px;
}

.card_text{

    padding: 2% 0% 5% 5%;
}

.trans_imgs{
    border-radius:10px 10px 0px 0px ;

    width: 100%;
    height:200px;
}



 

  .child_count{
display: none;

  }

  .showhere{
display: block;

  }

  @media only screen and (max-width:600px){

    .img-room-main{
   height:95%;
   width:100%; 
  }

.img-team-section{

    width: 23%;
    height: max-content;
    margin: 1% 1% 1% 1%;
    border-radius: 5px;
}
  }

  @media only screen and (min-width:768px){

    .img-room-main{
   height:90%;
   width:100%; 
  }

.img-team-section{

    width: 23%;
    height: max-content;
    margin: 0% 1% 0% 1%;
    border-radius: 2px;
}
  }

  @media only screen and (min-width:1200px){
.corner-bottom{

    border-radius:0px 0px 5px 0px;

}

.corner-top{
    border-radius:0px 5px 0px 0px;


}

.corner-right{

    border-radius:5px 0px 0px 5px;
}
    .img-room-main{
   height:95%;
   width:100%; 
  }

.col-lg-2-5row{

    width:20%;
}

   .img-team-section{

    width: 23%;
    height: max-content;
    margin: 0% 1% 0% 1%;
    border-radius: 5px;
   } 
  }

  .heightlight-text{

    
  }

  .img-room-group{


  }

  .gross{

    color: gray;
    font-size: 15px;
    background-color: transparent;
    box-shadow: inset -20px 0px 11px 0px #eee;
  }

  
 .footer-items{

    border-bottom:1px soild black;
    padding-bottom: 5px;
    color:white;
    text-align:center;
 }

 .setting-space{

    margin-bottom:4%;
 }

 /* carousel inner Start */
@media only screen and (max-width:600px){
    .carousel-inner-content-overlay{
        position: absolute;
        top: 57%;
        width: 93%;
        z-index: 10;
    
     }
    
     .search-mod{
       
        line-height: 2.5;
        border: none;
        border-radius: 5px;
        padding-left: 3%;
        width:100%;
    
     }
    
     .mod-btns{
        background-color: #ffa700 !important;
        color: white !important;
        border: none !important;
        font-size: 11px;
        margin-top: 5%;
    
     }

}

@media only screen and (min-width:768px){
    .carousel-inner-content-overlay{
        position: absolute;
        top: 50%;
        left: 20%;
        width: 60%;
        z-index: 10;
    
     }
    
     .search-mod{
        width: 100%;
        line-height: 2.5;
        border: none;
        border-radius: 5px;
        padding-left: 3%;
   
     }
    
     .mod-btns{
        background-color: #ffa700 !important;
        color: white !important;
        border: none !important;
        width: 115%;
        font-size: 13px;
    
     }

}

@media only screen and (min-width:1200px){
 .carousel-inner-content-overlay{
    position: absolute;
    top: 55%;
    left: 32%;
    width: 35%;
    z-index: 10;

 }

 .search-mod{
    width: 100%;
    line-height: 2.5;
    border: none;
    border-radius: 5px;
    padding-left: 3%;
    margin-top: 0px !important;

 }

 .mod-btns{
    background-color: #ffa700 !important;
    color: white !important;
    border: none !important;
    line-height: 1.7;
width:100%;
font-size:15px;
 }
}

.border-none{
border:none;

}
 /* carousel inner End */


 .btn-warning:hover{
background-color: orange !important;
color: white;
 }

 .btn::active{

    color: white;
     }

     textarea:focus, input:focus{
        outline: none;
    }

.nav-items-travel{
    
    margin: 0px 1px 0px 1px;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    color:gray;
    font-weight: 500;
}

.nav-items-travel:hover{
    margin: 0px 1px 0px 1px;
    padding: 10px;
    background-color: #001E79 !important ;
    color: white !important;
border-radius: 0px;
transform: translateY(-5px);
}


