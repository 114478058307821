.services-section1 {
    background: #FFFFFF;
}

.rounded-button {
    position: relative;
    line-height: 1;
    overflow: hidden;
    text-align: center;
    margin-bottom: 60px;
    max-width: 1250px;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    ;
}

.icons {
    background-color: #E9610C;
    color: #FFFFFF;
    height: 120px;
    width: 120px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    text-decoration: none;
}

.bottle {
    border-radius: 5%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 400px;
}